import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

import images from "./img/index";
import Button from "../Button";

import s from "./Carousel.module.scss";

export const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const carousel = useRef(null);

  useEffect(() => {
    setItemWidth(carousel.current.clientWidth);
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  return (
    <AnimatePresence>
      <div className={s.carousel}>
        <div className={s.carousel_inner}>
          {images.map(({ src, alt }, i) => {
            const isCurrent = currentIndex === i;
            const x = (i - currentIndex) * itemWidth;
            return (
              <div
                ref={carousel}
                key={i}
                className={s.wrapper}
                style={{
                  transform: `translateX(-${currentIndex * itemWidth}px)`
                }}>
                <motion.div
                  className={s.item}
                  key={i}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: isCurrent ? 1 : 0.5,
                    x: x,
                    scale: isCurrent ? 1 : 0.5,
                    zIndex: isCurrent ? 1 : 0
                  }}
                  transition={{
                    x: { type: "spring", stiffness: 300, damping: 40 },
                    opacity: { duration: 0.5 }
                  }}>
                  <motion.img
                    src={src}
                    alt={alt}
                    className={s.image}
                    initial={{ opacity: 0.5 }}
                    animate={{
                      opacity: isCurrent ? 1 : 0.5,
                      y: isCurrent ? 0 : 20
                    }}
                    transition={{
                      y: { type: "spring", stiffness: 300, damping: 30 },
                      opacity: { duration: 0.8 }
                    }}
                  />
                  <div className={s.overlay}></div>
                </motion.div>
                <p className={s.title}>{alt}</p>
              </div>
            );
          })}
        </div>

        <div className={s.arrow_buttons}>
          <motion.button
            className={s.arrow_btn}
            onClick={handleNextClick}
            whileHover={{ scale: 1.1 }}>
            <MdKeyboardArrowLeft className={s.icon} />
          </motion.button>
          <motion.button
            className={s.arrow_btn}
            onClick={handlePrevClick}
            whileHover={{ scale: 1.1 }}>
            <MdKeyboardArrowRight className={s.icon} />
          </motion.button>
        </div>
        <Button to="/clients">Клиенты</Button>
      </div>
    </AnimatePresence>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  )
};
