import React from "react";

import Headling from "../../components/components/Headling";
import About from "../../components/components/About";
import ScrollButton from "../../UI/ScrollUp";
import ScrollToTopOnMount from "../../UI/ScrollToTopOnMount";

import { ReactComponent as Logo } from "../../img/logo.svg";

import "../../css/page.scss";

export const Aboutpage = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <Headling />
      <div className="page">
        <h2 className="title">
          {" "}
          <Logo className="image" />
          <br /> - компания, которая предоставляет инженерное сопровождение и
          строительно-монтажные услуги на территории России.{" "}
        </h2>
        <About />
        <ScrollButton />
      </div>
    </>
  );
};
