export const sendData = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      const error = await response?.json();
      const errorData = new Error(`${response.text}`);
      error.message = errorData;
      return { error };
    }

    const message = await response?.text();
    return { message };
  } catch (error) {
    console.error("Ошибка:", error.message);
    return { error };
  }
};
