import React from "react";
import { motion } from "framer-motion";
import { MdBookmarkAdded } from "react-icons/md";

import Button from "../../../UI/Button";

import s from "./About.module.scss";

export const About = () => {
  return (
    <motion.div
      className={s.wrapper}
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <motion.div
        className={s.wrapperText}
        variants={{
          hidden: { opacity: 0, y: -20 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
        }}>
        <p className={s.text}>
          <MdBookmarkAdded className={s.icon} />
          <span className={s.text_word}>Наша компания</span> начала свою
          деятельность в 2021 году и за это время зарекомендовала себя как
          надежный партнер в области инженерного строительства.{" "}
        </p>
        <p className={s.text}>
          <MdBookmarkAdded className={s.icon} />
          <span className={s.text_word}>Основной профиль</span> нашей
          деятельности - это оформление различных сопроводительных,
          разрешительных и проектных документов, а также приемо-сдаточной и
          геодезической документации. Наши специалисты грамотно оформят все
          необходимые бумаги, связанные с проведением строительных работ, что
          позволит существенно сэкономить время и избежать ненужных затрат.
        </p>
        <p className={s.text}>
          <MdBookmarkAdded className={s.icon} />
          <span className={s.text_word}>Мы уделяем</span> особое внимание
          каждому клиенту, поэтому готовы предоставить индивидуальный подход в
          решении любых вопросов. Мы готовы предоставить вам полный спектр
          услуг, связанных с инженерным сопровождением и документацией. Наши
          специалисты тщательно изучат каждый проект и подберут наилучшие
          решения для успешной реализации задач.
        </p>
        <p className={s.text}>
          <MdBookmarkAdded className={s.icon} />
          <span className={s.text_word}>Компания Auрум</span> - это надежный
          партнер в инженерном строительстве, который гарантирует качество и
          надежность своих услуг. Мы готовы взять на себя ответственность за
          реализацию любого проекта и обеспечить его успешное завершение.
          Свяжитесь с нами и убедитесь в этом сами.
        </p>
      </motion.div>
      <Button to="/">На главную</Button>
    </motion.div>
  );
};
