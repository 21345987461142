import React from "react";

import { ReactComponent as Phone } from "./img/phone.svg";
import s from "./PhoneNumber.module.scss";

export const PhoneNumber = () => {
  return (
    <a className={s.wrapper} href="tel:+79999990026">
      <div className={s.telephone}>
        <Phone className={s.phone} />
      </div>
      <p className={s.number}>
        <span className={s.code}>+7 (999)</span> 999-00-26
      </p>
    </a>
  );
};
