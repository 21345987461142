import PropTypes from "prop-types";

export const setImage = async (img, format = "svg") => {
  let srcImg = null;

  try {
    srcImg = await import(`../img/${img}.${format}`);
  } catch (e) {
    console.error(e);
  }

  return srcImg?.default;
};

setImage.propTypes = {
  img: PropTypes.string.isRequired,
  format: PropTypes.string
};
