import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import CardList from "./List";
import Button from "../../../UI/Button";
import { setImage, scrollToHash } from "../../../utils";

import s from "./Card.module.scss";

export const Card = ({ title, img, list, link }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const getImg = async () => {
      const url = await setImage(img);
      setImgSrc(url);
    };
    getImg();
  }, [img]);

  useEffect(() => {
    scrollToHash(location);
  }, [location]);

  return (
    <article className={s.card} id={link}>
      <h3 className={s.title}>{title}</h3>
      <div className={s.wrapper}>
        <img src={imgSrc} alt={title} className={s.image} />
        <CardList list={list} />
      </div>
      <div className={s.link}>
        <Button to="#form">Получить помощь</Button>
      </div>
    </article>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  img: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};
