import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "./img/logo.svg";
import { ReactComponent as MiniLogo } from "./img/logo_mini.svg";

import s from "./Headling.module.scss";

export const Headling = () => {
  return (
    <div className={s.wrapper}>
      <motion.div
        className={s.gradientLine}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1
        }}
        transition={{ duration: 1.5 }}>
        <div className={s.wrapper_logo}>
          <Link to="/">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 0.7 }}>
              <MiniLogo className={s.mini_logo} />
            </motion.div>
          </Link>

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.7 }}>
            <Logo className={s.logo} />
          </motion.div>
        </div>

        <motion.h1
          className={s.title}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.9 }}>
          Время - <motion.span> золото</motion.span>
          <br />
          Вкладываем своё - сохраняем Ваше
        </motion.h1>
      </motion.div>
    </div>
  );
};
