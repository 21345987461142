import React from "react";

import Headling from "../../components/components/Headling";
import Clients from "../../components/components/Clients";
import ScrollButton from "../../UI/ScrollUp";
import ScrollToTopOnMount from "../../UI/ScrollToTopOnMount";

import { ReactComponent as Logo } from "../../img/logo.svg";

import "../../css/page.scss";

export const Clientspage = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <Headling />
      <div className="page">
        <h2 className="title">
          Опыт компании <Logo className="image" />
          <br /> уникален и отвечает потребностям наших клиентов.
        </h2>
        <h3 className="subtitle"> Наши клиенты</h3>

        <Clients />
        <ScrollButton />
      </div>
    </>
  );
};
