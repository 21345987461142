import React from "react";

import { ReactComponent as Envelope } from "./img/envelope.svg";
import { ReactComponent as Map } from "./img/map.svg";
import { ReactComponent as Phone } from "./img/telephone.svg";

import s from "./Info.module.scss";

export const Info = () => {
  return (
    <div className={s.info}>
      <a href="mailto:info@aurum-company.ru" className={s.text}>
        <Envelope className={s.image} />
        <p>info@aurum-company.ru</p>
      </a>

      <a href="tel:+79999990026" className={s.text}>
        <Phone className={s.image} />
        <p>+7(999) 999-00-26</p>
      </a>

      <p className={s.text}>
        <Map className={s.image} />
        350087, г. Краснодар, ул. Стасова, д.178/2 офис 309
      </p>
    </div>
  );
};
