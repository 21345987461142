import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { modalContext } from "../../../../context";
import Button from "../../../../UI/Button";
import { setImage } from "../../../../utils/setImage";

import s from "./ClientsList.module.scss";

export const ClientsList = ({ title, content, img }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const { openModal } = useContext(modalContext);

  const handleClick = () => {
    openModal({
      title: title,
      content: content
    });
  };

  useEffect(() => {
    const getImg = async () => {
      const url = await setImage(img, "png");
      setImgSrc(url);
    };

    getImg();
  }, [img]);

  return (
    <article className={s.preparation}>
      <div className={s.wrapper_image}>
        {imgSrc && <img src={imgSrc} alt={title} className={s.image} />}
      </div>

      <div className={s.overlay}></div>
      <p className={s.title}>{title}</p>
      <Button onClick={handleClick} className={s.btn}>
        Подробнее
      </Button>
    </article>
  );
};

ClientsList.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  id: PropTypes.string
};
