import { getRandomId } from "../utils/getRandomId";

export const clients = [
  {
    title: "МПФ МОНОЛИТ",
    content:
      "Выполненные работы: \n ЯНАО, М-р «Русское», Роснефть, АО «Тюменефтегаз»",
    img: "client1",
    id: getRandomId()
  },
  {
    title: "ООО СТРОЙ-ИНДУСТРИЯ",
    content:
      "Выполненные работы: \n Трубопровод СПБТ Восточно-Уренгойского участка",
    img: "client2",
    id: getRandomId()
  },
  {
    title: "ООО АЛЬЯНС",
    content:
      "Выполненные работы: \n Краснодарский край, Ильский НПЗ, Афипский НПЗ",
    img: "client3",
    id: getRandomId()
  },
  {
    title: "ООО АРМАДА",
    content:
      "Выполненные работы: \n ЯНАО, М-р «Русское», Роснефть, АО «Тюменефтегаз»",
    img: "client4",
    id: getRandomId()
  },
  {
    title: "МПФ МОНОЛИТ",
    content:
      "Выполненные работы: \n Тюменская область, с. Нижняя Тавда, реконструкция автомобильной дороги",
    img: "client5",
    id: getRandomId()
  },
  {
    title: "МПФ МОНОЛИТ",
    content:
      "Выполненные работы: \n ЯНАО, М-р «Русское», Роснефть, АО «Тюменьнефтегаз»",
    img: "client6",
    id: getRandomId()
  }
];
