/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import cn from "classnames";
import debounce from "lodash.debounce";

import { DropdownMenu } from "./DropdownMenu/DropdownMenu";
import { windowSizeContext } from "../../../../../context";

import s from "./Navigation.module.scss";

export const Navigation = ({ links, activeLink }) => {
  const { menuOpen, setMenuOpen, size, menuToggleHandler } =
    useContext(windowSizeContext);
  const scrollRef = useRef(0);

  const handleLeave = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      scrollRef.current = window.scrollY;
      if (scrollRef.current > 200) {
        setMenuOpen(false);
      }
    }, 250);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  return (
    <nav
      ref={scrollRef}
      className={cn(
        s.navigation,
        `${menuOpen && size.width < 768 ? `${s.isMenu} ` : ""}`
      )}
      onMouseLeave={handleLeave}>
      <ul className={s.list}>
        {links.map(({ id, title, to, children }) => {
          return (
            <li key={id} className={s.link}>
              {children ? (
                <DropdownMenu title={title}>
                  {children.map(({ title, to }, i) => {
                    return (
                      <li key={i}>
                        <NavLink
                          className={activeLink}
                          to={to}
                          onClick={menuToggleHandler}>
                          {title}
                        </NavLink>
                      </li>
                    );
                  })}
                </DropdownMenu>
              ) : (
                <NavLink
                  className={activeLink}
                  to={to}
                  onClick={menuToggleHandler}>
                  {title}
                </NavLink>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  activeLink: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired
};
