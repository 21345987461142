import React from "react";
import { Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import {
  Aboutpage,
  Homepage,
  Clientspage,
  Servicepage,
  Notfoundpage,
  Constructionpage
} from "../../../pages";
import Layout from "../Layout";

export const AnimatedRoutes = () => {
  return (
    <>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Homepage />} />
            <Route path="service">
              <Route path="engineering" element={<Servicepage />} />
              <Route path="construction" element={<Constructionpage />} />
            </Route>
            <Route path="clients" element={<Clientspage />} />
            <Route path="about" element={<Aboutpage />} />
            <Route path="*" element={<Notfoundpage />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </>
  );
};
