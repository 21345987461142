import React from "react";

import { ReactComponent as Medal } from "./img/medal.svg";
import { ReactComponent as Hands } from "./img/hands.svg";
import { ReactComponent as People } from "./img/people.svg";

import s from "./Info.module.scss";

export const Info = () => {
  return (
    <section className={s.container}>
      <div className={s.info}>
        <Medal className={s.icon} />

        <div className={s.text}>
          Качество
          <p className={s.subtext}>- гарантия на выполняемые работы</p>
          <p className={s.subtext}>- выполнение работ в поставленный срок</p>
        </div>
      </div>

      <div className={s.info}>
        <Hands className={s.icon} alt="Сотрудники" />

        <div className={s.text}>
          Профессионализм, эффективность, развитие
          <p className={s.subtext}>- опытные специалисты</p>
          <p className={s.subtext}>- обладаем собственным парком техники</p>
          <p className={s.subtext}>- решение задач любой сложности</p>
        </div>
      </div>

      <div className={s.info}>
        <People className={s.icon} alt="Доверие" />

        <div className={s.text}>
          Опыт и надежность
          <p className={s.subtext}>- с Вами до конца поставленной цели</p>
          <p className={s.subtext}>- нацеленность на результат</p>
          <p className={s.subtext}>
            - оперативность выполнения поставленных задач
          </p>
        </div>
      </div>
    </section>
  );
};
