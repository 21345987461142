import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [menuOpen, setMenuOpen] = useState(() => {
    const value = localStorage.getItem("menuOpen");
    return value !== null ? JSON.parse(value) : false;
  });

  const [size, setSize] = useState({
    width: 0,
    height: 0
  });

  const menuToggleHandler = () => {
    setMenuOpen(prev => !prev);
  };

  useEffect(() => {
    localStorage.setItem("menuOpen", JSON.stringify(menuOpen));
  }, [menuOpen]);

  useEffect(() => {
    const handleSize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener("resize", handleSize);
    handleSize();

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width]);

  return { menuOpen, setMenuOpen, size, setSize, menuToggleHandler };
};
