import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Container from "../Container";

import s from "./Layout.module.scss";

export const Layout = () => {
  return (
    <div className={s.app}>
      <Header />
      <main>
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </div>
  );
};
