import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Logo } from "./img/logo.svg";

import s from "./Rights.module.scss";

export const Rights = ({ currentYear }) => {
  return (
    <footer className={s.rights}>
      <Logo className={s.image} />
      <p className={s.title}>&copy; {currentYear} Все права защищены.</p>
      <nav className={s.navigation}>
        <ul>
          <li className={s.link}>
            <a
              href="https://github.com/vladimirplyukhin89"
              target="_blank"
              rel="noreferrer noopener">
              <p>
                Designed and coded by <span>me</span>
              </p>
            </a>
          </li>
          <li>
            <a
              href="https://openai.com/blog/chatgpt"
              target="_blank"
              rel="noreferrer noopener">
              <p>
                {" "}
                Checked by <span>ChatGPT</span>
              </p>
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

Rights.propTypes = {
  currentYear: PropTypes.string
};
