import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { modalContext } from "../../context/modalContext";
import Button from "../../UI/Button";

import s from "./Modal.module.scss";

export const Modal = props => {
  const { title, content } = props;
  const { closeModal } = useContext(modalContext);
  const [closing, setClosing] = useState(false);
  const modalClasses = closing
    ? `${cn(s.modal, s.modal_hidden)}`
    : `${s.modal}`;

  const handleClose = () => {
    setClosing(true);

    const closeTimeout = setTimeout(() => {
      closeModal();
      clearTimeout(closeTimeout);
    }, 300);
  };

  return (
    <div className={modalClasses} onClick={handleClose}>
      <div className={s.content} onClick={e => e.stopPropagation()}>
        <div className={s.close} onClick={handleClose}></div>
        <div className={s.body}>
          <h3 className={s.headling}>{title}</h3>
          <p className={s.text}>{content}</p>
        </div>
        <Button className={s.btn} onClick={handleClose}>
          ОК
        </Button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
};
