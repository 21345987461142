import React from "react";

import Container from "../Container";
import Info from "./Info";
import Form from "./Form";
import Rights from "./Rights";
import { getYear } from "../../../utils";

import s from "./Footer.module.scss";

export const Footer = () => {
  const currentYear = getYear();

  return (
    <Container>
      <footer className={s.footer}>
        <h2 className={s.title}>Работаем по всей России</h2>
        <h3 className={s.subtitle}>Офис в Краснодаре</h3>

        <Info />

        <h3 className={s.subtitle}>Свяжитесь с нами</h3>
        <p className={s.tender}>
          Задайте вопрос, пришлите коммерческое предложение или пригласите на
          тендер
        </p>

        <Form />
        <Rights currentYear={currentYear} />
      </footer>
    </Container>
  );
};
