import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import Button from "../../../../UI/Button";
import Loading from "../../../../UI/Loading";
import { loadingContext } from "../../../../context";
import { modalContext } from "../../../../context";
import {
  USERNAME_REGEXP,
  EMAIL_REGEXP,
  TEL_REGEXP,
  MAIL_URL
} from "../../../../constants";
import { sendData } from "../../../../api";
import { scrollToHash } from "../../../../utils";

import s from "./Form.module.scss";

export const Form = () => {
  const { openModal } = useContext(modalContext);
  const { loading, setLoading } = useContext(loadingContext);
  const location = useLocation();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset
  } = useForm({
    mode: "onBlur"
  });

  const onSubmit = async data => {
    const result = await sendData(MAIL_URL, data);
    setLoading(false);

    if (result.error != null) {
      openModal({
        title: "Извините",
        content: result.error.message
      });
    } else {
      openModal({
        title: "Спасибо",
        content: result.message
      });
    }

    reset();
  };

  useEffect(() => {
    scrollToHash(location);
  }, [location]);

  return (
    <form
      id="form"
      onSubmit={handleSubmit(onSubmit)}
      action={MAIL_URL}
      method="POST"
      className={s.form}>
      <>
        {loading ? (
          <Loading />
        ) : (
          <fieldset className={s.fieldset}>
            <legend>Ваши данные</legend>
            <div className={s.wrapper}>
              <div className={s.block}>
                <label className={s.label}>
                  Представьтесь, пожалуйста*:
                  <input
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Поле обязательно к заполнению"
                      },
                      pattern: {
                        value: USERNAME_REGEXP,
                        message: "Введите только буквенные значения"
                      },
                      minLength: {
                        value: 3,
                        message: "Введите минимум 3 символа"
                      }
                    })}
                    name="name"
                    placeholder="ФИО"
                    className={s.input}
                  />
                </label>
                <div>
                  {errors?.name && (
                    <p className={s.error}>
                      {errors?.name?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>

              <div className={s.block}>
                <label className={s.label}>
                  Ваш телефон*:
                  <input
                    {...register("phone", {
                      required: {
                        value: true,
                        message: "Поле обязательно к заполнению"
                      },
                      pattern: {
                        value: TEL_REGEXP,
                        message: "Введите только цифровые значения"
                      },
                      maxLength: {
                        value: 12,
                        message: "Введите корректный номер телефона"
                      }
                    })}
                    name="phone"
                    placeholder="+7 (___)___-__-__"
                    className={s.input}
                  />
                </label>
                <div>
                  {errors?.phone && (
                    <p className={s.error}>
                      {errors?.phone?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={s.wrapper}>
              <div className={s.block}>
                <label className={s.label}>
                  Ваш email:
                  <input
                    {...register("email", {
                      pattern: {
                        value: EMAIL_REGEXP,
                        message: "Введите корректный email"
                      }
                    })}
                    name="email"
                    placeholder="Email"
                    className={s.input}
                  />
                </label>
                <div>
                  {errors?.email && (
                    <p className={s.error}>
                      {errors?.email?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>

              <div className={s.block}>
                <label className={s.label}>
                  Тема сообщения:
                  <input
                    {...register("message", {
                      maxLength: {
                        value: 80,
                        message: "Тема сообщения не должна превышать 80 знаков"
                      }
                    })}
                    name="message"
                    placeholder="Тема"
                    className={s.input}
                  />
                </label>
                <div>
                  {errors?.message && (
                    <p className={s.error}>
                      {errors?.message?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <label htmlFor="checkbox" className={s.agreement}>
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                className={s.checkbox}
                required
              />
              Я соглашаюсь с{" "}
              {/* <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                className={s.agreement_link}>
                политикой конфиденциальности*
              </a> */}
              <span className={s.agreement_link}>
                политикой конфиденциальности*
              </span>
            </label>

            <div className={s.btn_send}>
              <Button type="submit" disabled={!isValid}>
                Отправить
              </Button>
            </div>
          </fieldset>
        )}
      </>
    </form>
  );
};
