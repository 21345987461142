import { createContext, useState } from "react";
import PropTypes from "prop-types";

import Modal from "../UI/Modal";

export const modalContext = createContext({
  openModal: () => {},
  closeModal: () => {}
});

export const ModalContextProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = modalConfig => {
    setModalContent(modalConfig);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <modalContext.Provider value={{ openModal, closeModal }}>
      {modalOpen && <Modal {...modalContent} />}
      {children}
    </modalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
  modalOpen: PropTypes.bool,
  modalContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null])
  ]),
  children: PropTypes.node.isRequired
};
