import React from "react";

import { clients } from "../../../data/clients";
import ClientsList from "./List";
import Button from "../../../UI/Button";

import s from "./Clients.module.scss";

export const Clients = () => {
  return (
    <>
      <div className={s.wrapper}>
        {clients.map((client, id) => (
          <ClientsList key={id} {...client} />
        ))}
      </div>
      <Button to="/">На главную</Button>
    </>
  );
};
