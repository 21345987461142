import React, { useContext } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import { windowSizeContext } from "../../../../../context";

import s from "./BurgerBtn.module.scss";

export const BurgerBtn = () => {
  const { menuOpen, menuToggleHandler } = useContext(windowSizeContext);

  return (
    <div className={s.toggle}>
      {!menuOpen ? (
        <BiMenuAltRight onClick={menuToggleHandler} />
      ) : (
        <AiOutlineClose onClick={menuToggleHandler} />
      )}
    </div>
  );
};
