import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Button from "../../../../UI/Button";

import s from "./Hero.module.scss";

export const Hero = ({ imgSrc, list, headling }) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleDropdown = () => {
    setOpenDropdown(true);
  };

  return (
    <div className={s.card}>
      <img src={imgSrc} alt="Услуги" className={s.image} />
      <div className={s.overlay}></div>
      <p className={s.title}>{headling}</p>
      <Button onClick={handleDropdown} className={s.card__btn}>
        Открыть
      </Button>
      {openDropdown && (
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={s.dropdown}>
          <ul className={s.list}>
            {list.map(item => {
              const { mainLink, title, link } = item;
              const path = `${mainLink}#${link}`;
              return (
                <li key={link} className={s.link}>
                  <Link to={path} className={s.active}>
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

const listItemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  mainLink: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string),
  img: PropTypes.string,
  id: PropTypes.string.isRequired
});

const listShape = PropTypes.arrayOf(listItemShape);

Hero.propTypes = {
  headling: PropTypes.string,
  imgSrc: PropTypes.string,
  list: listShape
};
