import React from "react";

import ScrollToTopOnMount from "../../UI/ScrollToTopOnMount";
import Button from "../../UI/Button";

export const Notfoundpage = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="page">
        <h2 className="subtitle">Данной страницы не существует</h2>
        <Button to="/" className="home_btn">
          На главную
        </Button>
      </div>
    </>
  );
};
