import React, { useState } from "react";
import PropTypes from "prop-types";

import s from "./DropdownMenu.module.scss";

export const DropdownMenu = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  return (
    <div className={s.dropdown} onMouseLeave={closeMenu}>
      <div className={s.link} onMouseEnter={openMenu}>
        {title}
      </div>
      {isOpen && (
        <ul className={s.dropdownList} onClick={closeMenu}>
          {children}
        </ul>
      )}
    </div>
  );
};

DropdownMenu.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};
