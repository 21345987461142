import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Link } from "react-router-dom";

import s from "./Button.module.scss";

export const Button = props => {
  const { children, className, onClick, disabled, to, type, ...rest } = props;
  const Tag = to ? Link : "button";

  return (
    <Tag
      className={cn(s.container, className)}
      onClick={onClick}
      disabled={disabled}
      to={to}
      type={type}
      {...rest}>
      <span className={s.text}>{children}</span>
    </Tag>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["submit", "reset", "button"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  to: PropTypes.string
};
