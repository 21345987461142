import React from "react";

import Hero from "./Hero";
import { constructionServices } from "../../../data/construction";
import { engineeringServices } from "../../../data/engineering";

import FirstCard from "./Hero/img/card1.jpg";
import SecondCard from "./Hero/img/card2.jpg";

import s from "./HeroContainer.module.scss";

export const HeroContainer = () => {
  return (
    <div className={s.wrapper} id="hero">
      <Hero
        imgSrc={FirstCard}
        list={engineeringServices}
        headling="Инженерные услуги"
      />
      <Hero
        imgSrc={SecondCard}
        list={constructionServices}
        headling="Строительно-монтажные услуги"
      />
    </div>
  );
};
