import React from "react";

import Container from "../Container";
import PhoneNumber from "./TelNumber";

import Menu from "./Menu";

import { links } from "../../../data/links";

import s from "./Header.module.scss";

export const Header = () => {
  return (
    <Container>
      <header className={s.header}>
        <div className={s.content}>
          <PhoneNumber />
          <Menu links={links} />
        </div>
      </header>
    </Container>
  );
};
