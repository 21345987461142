import img1 from "./client1.png";
import img2 from "./client2.png";
import img3 from "./client3.png";
import img4 from "./client4.png";
import img5 from "./client5.png";
import img6 from "./client6.png";

const images = [
  { src: img1, alt: "МПФ МОНОЛИТ" },
  { src: img2, alt: "ООО СТРОЙ-ИНДУСТРИЯ" },
  { src: img3, alt: "ООО АЛЬЯНС" },
  { src: img4, alt: "ООО АРМАДА" },
  { src: img5, alt: "МПФ МОНОЛИТ" },
  { src: img6, alt: "МПФ МОНОЛИТ" }
];

export default images;
