import React, { useContext } from "react";

import AnimatedRoutes from "./components/AnimatedRoutes";
import { loadingContext } from "../context";
import Loading from "../UI/Loading";

function App() {
  const { loading, setLoading } = useContext(loadingContext);

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  return <>{loading ? <Loading /> : <AnimatedRoutes />}</>;
}

export default App;
