import React from "react";

import Button from "../../../UI/Button";

import Info from "./Info";

import s from "./Principles.module.scss";

export const Principles = () => {
  return (
    <section className={s.principles}>
      <h2 className={s.title}>Наши принципы</h2>
      <div className={s.wrapper}>
        <Info />
        <div className={s.technique}></div>
      </div>

      <div className={s.block_btn}>
        <Button to="/about">Подробнее о компании</Button>
      </div>
    </section>
  );
};
