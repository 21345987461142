import { createContext } from "react";
import PropTypes from "prop-types";

import { useWindowSize } from "../hooks/useWindowSize";

export const windowSizeContext = createContext({});

export const WindowSizeProvider = ({ children }) => {
  const { menuOpen, setMenuOpen, size, menuToggleHandler } = useWindowSize();

  return (
    <windowSizeContext.Provider
      value={{ menuOpen, setMenuOpen, size, menuToggleHandler }}>
      {children}
    </windowSizeContext.Provider>
  );
};

WindowSizeProvider.propTypes = {
  children: PropTypes.node.isRequired
};
