import { assignId } from "../utils";

export const links = [
  { title: "Главная", to: "/" },
  {
    title: "Услуги",
    to: "/service",
    children: [
      { title: "Инженерные услуги", to: "/service/engineering" },
      { title: "Строительно-монтажные услуги", to: "/service/construction" }
    ]
  },
  { title: "Клиенты", to: "/clients" },
  { title: "О нас", to: "/about" }
].map(assignId);
