import { getRandomId } from "../utils";

export const constructionServices = [
  {
    mainLink: "/service/construction",
    title: "Общестроительные работы",
    list: [
      "Земляные и фундаментные работы",
      "Устройство монолитных и сборных железобетонных конструкций",
      "Изготовление и монтаж металлических конструкций",
      "Изготовление и монтаж технологического оборудования",
      "Монтаж ограждающих конструкций стен и кровли",
      "Cвайные работы (бурение, погружение)",
      "Отделочные работы"
    ],
    id: getRandomId(),
    img: "doc7",
    link: "general"
  },
  {
    mainLink: "/service/construction",
    title: "Специализированные работы",
    list: [
      "Электромонтажные работы",
      "Отопление",
      "Вентиляция",
      "Водоснабжение и канализация"
    ],
    id: getRandomId(),
    img: "doc8",
    link: "specialized"
  },
  {
    mainLink: "/service/construction",
    title: "Дорожные работы",
    list: ["Отсыпка", "Асфальто-бетонные работы", "Благоустройство территории"],
    id: getRandomId(),
    img: "doc9",
    link: "road"
  }
];
