import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { ModalContextProvider } from "./context/modalContext";
import { WindowSizeProvider } from "./context/windowSizeContext";
import { LoadingProvider } from "./context/loadingContext";
import App from "./components/App";

import "./css/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <LoadingProvider>
        <WindowSizeProvider>
          <ModalContextProvider>
            <App />
          </ModalContextProvider>
        </WindowSizeProvider>
      </LoadingProvider>
    </Router>
  </React.StrictMode>
);
