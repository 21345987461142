import React from "react";

import Headling from "../../components/components/Headling";
import HeroContainer from "../../components/components/HeroContainer";
import Principles from "../../components/components/Principles";
import Carousel from "../../UI/Carousel";
import ScrollButton from "../../UI/ScrollUp";

export const Homepage = () => {
  return (
    <>
      <Headling />
      <HeroContainer />
      <Principles />
      <Carousel />
      <ScrollButton />
    </>
  );
};
