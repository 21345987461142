import React from "react";

import { constructionServices } from "../../data/construction.js";

import Headling from "../../components/components/Headling";
import Card from "../../components/components/Card";
import Button from "../../UI/Button";
import ScrollButton from "../../UI/ScrollUp";

import "../../css/page.scss";

export const Constructionpage = () => {
  return (
    <>
      <Headling />
      <div className="page">
        <h2 className="title">Строительно-монтажные услуги</h2>
        {constructionServices.map(service => {
          const { id, ...rest } = service;

          return <Card key={id} {...rest} />;
        })}
        <Button to="/">На главную</Button>
        <ScrollButton />
      </div>
    </>
  );
};
