import React from "react";
import PropTypes from "prop-types";

import s from "./List.module.scss";

export const CardList = ({ list }) => {
  return (
    <ul className={s.content}>
      {list.map((item, i) => (
        <li key={i} className={s.text}>
          <p className={s.subtext}>{item}</p>
        </li>
      ))}
    </ul>
  );
};

CardList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired
};
