import PropTypes from "prop-types";

import Navigation from "./Navigation";
import BurgerBtn from "./BurgerBtn";

import s from "./Navigation/Navigation.module.scss";

export const Menu = ({ links }) => {
  const activeLink = ({ isActive }) => (isActive ? `${s.active}` : "");

  return (
    <>
      <Navigation links={links} activeLink={activeLink} />
      <BurgerBtn />
    </>
  );
};

Menu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired
};
