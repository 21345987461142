import { getRandomId } from "../utils";

export const engineeringServices = [
  {
    mainLink: "service/engineering",
    title: "Оформление сопроводительной документации",
    list: [
      "Оформление АОСР",
      "Оформление исполнительных схем",
      "Формирование сертификации и паспортов",
      "Комплектация",
      "Аудит исполнительной документации",
      "Написание и формирование специальных журналов и журнала общественных работ"
    ],
    id: getRandomId(),
    img: "doc1",
    link: "supporting"
  },
  {
    mainLink: "service/engineering",
    title: "Оформление проектной документации",
    list: [
      "Аудит объекта по отклонениям и изменениям",
      "Внесение изменений в проект",
      "Отработка с проектным институтом",
      "Пересогласование проекта",
      "Разработка проекта"
    ],
    id: getRandomId(),
    img: "doc2",
    link: "design"
  },
  {
    mainLink: "service/engineering",
    title: "Оформление разрешительной документации",
    list: [
      "Формирование пакета разрешительной документации",
      "Оформление ППР, ППРк, ТК"
    ],
    id: getRandomId(),
    img: "doc3",
    link: "licensing"
  },
  {
    mainLink: "service/engineering",
    title: "Оформление приёмо-сдаточной документации",
    list: [
      "Оформление и доработка ПСД",
      "Сдача объекта в контролирующие органы"
    ],
    id: getRandomId(),
    img: "doc4",
    link: "acceptance"
  },
  {
    mainLink: "service/engineering",
    title: "Геодезические услуги",
    list: ["Съёмка", "Разбивка", "Камеральная обработка", "Оформление схем"],
    id: getRandomId(),
    img: "doc5",
    link: "geodetic"
  },
  {
    mainLink: "service/engineering",
    title: "Лабораторные услуги",
    list: [
      "Услуги общестроительной лаборатории",
      "Улуги неразрушающегося контроля",
      "Услуги лабораторий"
    ],
    id: getRandomId(),
    img: "doc6",
    link: "laboratory"
  }
];
